.clickable {
  cursor: pointer;
}
.margin-top {
  margin-top: 15px  !important;
}
.margin-top--none {
  margin-top: 0  !important;
}
.margin-bottom {
  margin-bottom: 15px !important;
}
.margin-bottom--none {
  margin-bottom: 0  !important;
}
.modal {
  z-index: 1062;
}
.modal-backdrop {
  z-index: 1061;
}
/* the norm */
#gritter-notice-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 301px;
  z-index: 999999;
}
#gritter-notice-wrapper.top-left {
  left: 20px;
  right: auto;
}
#gritter-notice-wrapper.bottom-right {
  top: auto;
  left: auto;
  bottom: 20px;
  right: 20px;
}
#gritter-notice-wrapper.bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
}
.gritter-item-wrapper {
  position: relative;
  margin: 0 0 10px 0;
}
.gritter-top {
  height: 10px;
}
.hover .gritter-top {
  background-position: right -30px;
}
.gritter-bottom {
  height: 8px;
  margin: 0;
}
.hover .gritter-bottom {
  background-position: bottom right;
}
.gritter-item {
  display: block;
  padding: 2px 11px 8px 11px;
  font-size: 12px;
  z-index: 999999;
}
.hover .gritter-item {
  background-position: right -40px;
}
.gritter-item p {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}
.gritter-close {
  display: none;
  position: absolute;
  top: -6px;
  left: -6px;
  background: url(../images/gritter.png) no-repeat left top;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-indent: -9999em;
}
.gritter-title {
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 7px 0;
  display: block;
}
.gritter-image {
  width: 48px;
  height: 48px;
  float: left;
}
.gritter-with-image,
.gritter-without-image {
  padding: 0;
}
.gritter-with-image {
  width: 220px;
  float: right;
}
.gritter-light .gritter-title {
  text-shadow: none;
}
.bidlogix-app .grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}
.bidlogix-app .grid-container--item {
  flex-basis: 320px;
  flex-grow: 0;
  max-width: 400px;
  margin: 15px;
}
.bidlogix-app .grid-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.bidlogix-app .grid-content--labels {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: right;
  line-height: 1;
}
.bidlogix-app .grid-content--labels .label {
  margin-bottom: 0.5em;
}
.bidlogix-app .grid-content--labels .label img {
  vertical-align: text-bottom;
}
.bidlogix-app .grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.bidlogix-app .grid-item--thumbnail {
  border-radius: 3px;
}
.bidlogix-app .grid-item--content {
  flex-grow: 1;
}
.bidlogix-app .grid-item--grow {
  flex-grow: 1;
}
.bidlogix-app .grid-item--shrink {
  flex-shrink: 1;
}
.bidlogix-app .grid-item--no-grow {
  flex-grow: 0;
}
.bidlogix-app .grid-item--no-shrink {
  flex-shrink: 0;
}
.bidlogix-app .list-item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.bidlogix-app .list-item--thumbnails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 200px;
  flex-grow: 1;
  flex-shrink: 1;
}
.bidlogix-app .list-item--thumbnails div {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 30%;
  min-width: 100px;
}
.bidlogix-app .list-item--thumbnails div:nth-child(n+7) {
  display: none;
}
.bidlogix-app .list-item--thumbnails a:hover {
  text-decoration: none;
}
.bidlogix-app .list-item--content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
}
.bidlogix-app .list-item--labels {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: right;
  line-height: 1;
}
.bidlogix-app .list-item--labels .label {
  margin-bottom: 0.5em;
}
.bidlogix-app .list-item--labels .label img {
  vertical-align: text-bottom;
}
.bidlogix-app .list-item--grow {
  flex-grow: 1;
}
.bidlogix-app .list-item--shrink {
  flex-shrink: 1;
}
.bidlogix-app .list-item--no-grow {
  flex-grow: 0;
}
.bidlogix-app .list-item--no-shrink {
  flex-shrink: 0;
}
.bidlogix-app .list-item--container {
  position: relative;
}
.bidlogix-app .list-thumbnail img,
.bidlogix-app .list-thumbnail div {
  border: 1px solid #eee;
  border-radius: 3px;
  display: inline-block;
}
.bidlogix-app .overlay-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
}
.bidlogix-app .overlay-loader--content {
  display: flex;
  margin: 0 auto;
}
.bidlogix-app #myModal {
  z-index: 1062;
}
.bidlogix-app #myModal > div:not(.modal-dialog) {
  pointer-events: none;
}
.bidlogix-app #myModal .modal-dialog {
  pointer-events: auto;
}
.bidlogix-app #itemMessageRegion .popover,
.bidlogix-app #auctionMessage.popover {
  display: block;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}
.bidlogix-app #itemMessageRegion .popover {
  max-width: 80%;
  min-width: 50%;
}
.bidlogix-app #auctionMessage.popover {
  font-size: 2rem;
  max-width: 600px;
  text-align: center;
}
.bidlogix-app .img-error-preview,
.bidlogix-app .img-error-gal-home,
.bidlogix-app .img-error-list-home {
  text-align: center;
  padding: 14% 0;
}
.bidlogix-app .img-error-preview:before,
.bidlogix-app .img-error-gal-home:before,
.bidlogix-app .img-error-list-home:before {
  content: "\f03e";
  display: block;
  font-family: FontAwesome;
  font-size: 8em;
}
.bidlogix-app .img-error-thumb > div.img-error-container {
  padding-bottom: 75%;
  position: relative;
}
.bidlogix-app .img-error-thumb > div.img-error-container > div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 15px;
}
.bidlogix-app .img-error-list-home--thumbnail {
  font-size: 0.7em;
  padding: 14% 0;
}
.bidlogix-app .img-error-list-home--thumbnail:before {
  display: block;
  content: "\f03e";
  font-family: FontAwesome;
  font-size: 3em;
}
.bidlogix-app .thumbnail a:hover {
  text-decoration: none;
}
.bidlogix-app #tabControlView.nav,
.bidlogix-app .panel #tabsRegion .nav-tabs {
  padding-left: 15px;
  padding-right: 15px;
}
.bidlogix-app .third-party--google-map {
  padding-bottom: 80%;
}
.bidlogix-app .typeahead.dropdown-menu {
  max-width: 100%;
}
.bidlogix-app .typeahead.dropdown-menu li {
  margin-right: 0;
}
.bidlogix-app .typeahead.dropdown-menu li a {
  text-overflow: ellipsis;
  overflow: hidden;
}
.bidlogix-app .img-error-gal-home:before {
  content: "\f03e";
  font-family: FontAwesome;
  font-size: 80px;
}
