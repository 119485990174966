@node-modules-path: "../../../node_modules/";

@import "./includes/global.less";

.bidlogix-app {
  // Import Bidlogix less files
  @import "my-variables.less";

  @import "./includes/includes.less";
}
